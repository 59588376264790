import PermissionsClass from '@/security/Permission-classes'
import Permissions from '@/security/permissions'

export class SidebarPermissions extends PermissionsClass {
  constructor(currentUser) {
    super(currentUser, {
      read: Permissions.sidebarRead,
      create: Permissions.sidebarCreate,
      edit: Permissions.sidebarEdit,
      delete: Permissions.sidebarDestroy
    })
  }
}
