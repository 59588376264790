<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('sidebar.title') }}</h2>
    </div>
    <!-- <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter> -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            v-if="hasPermissionToCreate"
            class="btn bg-theme-31 text-white w-1/2 sm:w-auto mr-2 ml-2"
            @click="$router.push('/sidebar/new')"
          >
            <PlusIcon class="w-4 h-4 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary dark:text-white w-1/2 sm:w-auto mr-2 ml-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
        </div>
        <div class="flex mt-5 sm:mt-0">
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary dark:text-white w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <loading-spinner
          type="controller"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div ref="table" class="relative">
          <div class="overflow-x-auto">
            <table
              class="table table-report sm:mt-2"
              id="promoCodeTable"
              ref="tableRef"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap w-10" id="ignore-6">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap w-20"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  draggable="true"
                  class="cursor-move"
                  :data-index="index"
                  @dragstart="handleDragStart"
                  @dragenter="handleDragEnter"
                  @dragleave="handleDragLeave"
                  @dragover="handleDragOver"
                  @drop="handleDrop"
                >
                  <td class="w-10">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap cursor-pointer">
                      {{ presenterLocalization(row, 'title') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap cursor-pointer">
                      <img
                        :src="
                          row.imageIcon
                            ? row.imageIcon.publicUrl
                            : 'https://placehold.co/500x500?text=image'
                        "
                        :alt="
                          row.imageIcon ? row.imageIcon.name : 'placeholder'
                        "
                        class="w-10"
                      />
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap cursor-pointer">
                      {{ presenter(row, 'type') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'itemId') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'position') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                    <div class="font-small whitespace-nowrap mt-1">
                      {{ presenterTime(row, 'createdAt') }}
                    </div>
                  </td>
                  <td class="w-40">
                    <div
                      class="flex items-center justify-center"
                      :class="
                        !row.isActive
                          ? 'text-theme-24'
                          : 'text-green-600 dark:text-theme-30'
                      "
                    >
                      <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                      {{
                        !row.isActive
                          ? i18n('common.disabled')
                          : i18n('common.activated')
                      }}
                    </div>
                  </td>
                  <td class="table-report__action w-56" id="ignore-4">
                    <div class="flex justify-center items-center gap-2">
                      <router-link
                        v-if="hasPermissionToEdit"
                        class="text-green-600 flex items-center gap-1 whitespace-nowrap"
                        :to="`/sidebar/edit/${row.id}`"
                      >
                        <EditIcon class="w-4 h-4" />
                        {{ i18n('common.edit') }}
                      </router-link>
                      <a
                        v-if="hasPermissionToDestroy"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#delete-modal-preview"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white text-theme-24 hover:bg-gray-200 rounded-md"
                        @click="selectedId = row.id"
                        ><Trash2Icon class="w-4 h-4 mr-1 ml-1" />{{
                          i18n('common.delete')
                        }}</a
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.sidebar')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <!-- <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            :isFirstPage="isFirstPage"
            :isLastPage="isLastPage"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination> -->
        </div>
      </div>
      <DeleteModal item="sideMenu" @delete="doDestroy" />
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import dayjs from 'dayjs'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { SidebarPermissions } from './sidebar-permissions'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.sidebar')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.sidebar')
    }
  },
  components: {
    DeleteModal
  },
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectedId = ref('')
    const selectAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectedId,
      tabulator,
      selectAll,
      tableRef
    }
  },
  data() {
    return {
      filterInput: [],
      columns: [
        {
          name: 'image',
          field: 'image',
          label: 'sidebar.fields.image',
          align: 'center'
        },
        {
          name: 'title',
          field: 'title',
          label: 'sidebar.fields.title',
          align: 'center'
        },
        {
          name: 'type',
          field: 'type',
          label: 'sidebar.fields.type',
          align: 'center'
        },
        {
          name: 'itemId',
          field: 'itemId',
          label: 'sidebar.fields.itemId',
          align: 'center'
        },
        {
          name: 'position',
          field: 'position',
          label: 'sidebar.fields.position',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'sidebar.fields.createdAt',
          align: 'center'
        },
        {
          name: 'isActive',
          field: 'isActive',
          label: 'sidebar.fields.isActive',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'sidebar/list/rows',
      currentUser: 'auth/currentUser',
      currentPage: 'sidebar/list/currentPage',
      isFirstPage: 'sidebar/list/isFirstPage',
      isLastPage: 'sidebar/list/isLastPage',
      pagination: 'sidebar/list/pagination',
      loading: 'sidebar/list/loading'
    }),
    hasPermissionToCreate() {
      return new SidebarPermissions(this.currentUser).create
    },
    hasPermissionToEdit() {
      return new SidebarPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new SidebarPermissions(this.currentUser).delete
    },
    hasPermissionToChangeStatus() {
      return new SidebarPermissions(this.currentUser).changeStatus
    },
    typeOptions() {
      return [
        {
          label: this.i18n('common.staticCollection'),
          value: 'staticCollection'
        },
        { label: this.i18n('common.staticOccasion'), value: 'staticOccasion' },
        { label: this.i18n('common.collection'), value: 'collection' },
        { label: this.i18n('common.product'), value: 'product' },
        { label: this.i18n('common.blog'), value: 'blog' },
        { label: this.i18n('common.article'), value: 'article' },
        { label: this.i18n('common.page'), value: 'page' },
        { label: this.i18n('common.webview'), value: 'webview' }
      ]
    },
    statusOptions() {
      return [
        {
          label: this.i18n('common.activated'),
          value: true
        },
        { label: this.i18n('common.disabled'), value: false }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('sidebar.fields.title'),
          value: 'title',
          type: 'text',
          localize: true
        },
        {
          label: this.i18n('sidebar.fields.type'),
          value: 'type',
          type: 'list',
          options: this.typeOptions
        },
        {
          label: this.i18n('sidebar.fields.itemId'),
          value: 'itemId',
          type: 'text'
        },
        {
          label: this.i18n('sidebar.fields.isActive'),
          value: 'isActive',
          type: 'list',
          options: this.statusOptions
        }
      ]
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    isExpired() {
      return row => {
        return dayjs().isAfter(dayjs(row.endDate)) || row.status == 'disabled'
      }
    }
    // isExpired() {
    //   return dayjs().isAfter(dayjs('20 Aug, 2022'))
    // }
  },
  async mounted() {
    await this.doFetchFirstPage()
    // this.doMountTable(this.$refs.table)
  },
  methods: {
    ...mapActions({
      doFetch: 'sidebar/list/doFetch',
      doFetchNextPage: 'sidebar/list/doFetchNextPage',
      doFetchPreviousPage: 'sidebar/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'sidebar/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'sidebar/list/doChangePaginationPageSize',
      doRemove: 'sidebar/list/doRemove',
      doArrangeSidebar: 'sidebar/list/doArrangeSidebar'
    }),
    async doDestroy() {
      await this.doRemove(this.selectedId)
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter) return
      this.filterInput = filter
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterFullName(row, fieldName) {
      return GenericModel.presenterFullName(row, fieldName)
    },
    presenterEmail(row, fieldName) {
      return GenericModel.presenterEmail(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          if (!this.isExpired(element)) {
            this.selected.push(element.id)
          }
        })
      }
    },
    openCreatePage() {
      this.$router.push('/offer/new')
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'promoCodeTable', 'promoCode')
    },
    onDownloadPdf() {
      $h.downloadPdf('#promoCodeTable', 'promoCode', [1, 9])
    },
    onPrint() {
      print({
        printable: 'promoCodeTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 230,
        ignoreElements: [
          // 'ignore-1',
          'ignore-2',
          'ignore-3',
          'ignore-4',
          'ignore-5',
          'ignore-6'
        ]
      })
    },
    async doFetchFirstPage(orderBy = 'createdAt') {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        orderBy,
        pagination: {
          limit: 10,
          sortBy: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    handleDragStart(e) {
      e.dataTransfer.setData('text/plain', e.currentTarget.dataset.index)
    },
    handleDragEnter(e) {
      e.currentTarget.classList.add('hover')
    },
    handleDragLeave(e) {
      e.currentTarget.classList.remove('hover')
    },
    handleDragOver(e) {
      e.preventDefault()
    },
    handleDrop(e) {
      e.preventDefault()
      const itemIndex = e.dataTransfer.getData('text/plain')
      const droppedIndex = e.currentTarget.dataset.index
      Array.from(e.currentTarget.parentNode.children).map(tr => {
        tr.classList.remove('hover')
      })
      this.handleListChange(itemIndex, droppedIndex)
    },
    handleListChange(index, newIndex) {
      if (index == newIndex) return
      const oldIndex = parseInt(index, 10)
      const newPositionIndex = parseInt(newIndex, 10)
      const movedItem = this.rows[oldIndex]
      this.rows.splice(oldIndex, 1)
      this.rows.splice(newPositionIndex, 0, movedItem)
      this.doArrange()
    },
    async doArrange() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        const list = this.rows.map(row => row.id)
        this.doArrangeSidebar(list)
        clearTimeout(this.timer)
      }, 2000)
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
